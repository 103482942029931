<!-- Use este arquivo como base para novos módulos de CRUD, -->
<!-- apenas copiando ele e colando na pasta respectiva -->

<template>
  <CTabs variant="tabs" class="nav-tabs-boxed">
    <CTab title="Cadastro de filiais">
      <!-- Caso este não seja o módulo principal, leve as abas para o principal -->
      <crud-base
        :url="url"
        :columns="columns"
        :onDelete="() => {}"
        :onUpdate="() => {}"
        :onStore="() => {}"
        :enableSearch="true"
        :checkAlerts="true"
        primaryKey="id"
      />
    </CTab>
  </CTabs>
</template>

<script>
import CrudBase from "../../components/crud/CrudBase";
import {yesNoRepresentation} from "@/helpers/transformers";
export default {
  name: "crud-template",
  components: {
    CrudBase,
  },
  data() {
    return {
      url: "/admin/filiais",
      columns: [
        { label: "Id", field: "id", sortable: false },
        { label: "Ordem", field: "ordemexibicao", sortable: false },
        { label: "Ativo", field: "ativo", sortable: false, representedAs: (row) => yesNoRepresentation(row.ativo)},
        { label: "Razão Social", field: "razaosocial", sortable: false },
        { label: "Fantasia", field: "fantasia", sortable: false },
        { label: "UF", field: "uf", sortable: false },
      ],
    };
  },
};
</script>
